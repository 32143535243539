<template>
  <VueScroll :ops="ops" v-bind="$attrs" v-on="$listeners">
    <slot />
  </VueScroll>
</template>

<script>
import VueScroll from 'vuescroll/dist/vuescroll-native'
export default {
  name: 'FlotoScrollView',
  components: { VueScroll },
  props: {
    showDuration: { type: Number, default: 500 },
  },
  computed: {
    ops() {
      return {
        vuescroll: {
          detectResize: false,
        },
        scrollPanel: {
          speed: 100,
          easing: 'easeInOutCubic',
        },
        bar: {
          background: 'rgba(20, 33, 45, 0.5)',
          opacity: 0.5,
          size: '8px',
          specifyBorderRadius: '1px',
          showDelay: this.showDuration,
          keepShow: this.showDuration === 0,
        },
        rail: {
          background: 'transparent',
          opacity: 1,
          size: '8px',
        },
      }
    },
  },
}
</script>
